import Layout from '../../layouts/Layout'
import { Helmet } from 'react-helmet'
import bgImage from '../images/thank-you-2022-background.jpg'
import * as React from 'react'
import AnimateEntrance from '../../components/Esg/AnimateEntrance'
import NextSection from '../../components/NextSection/NextSection'

import {
  pages,
  mainSection,
  quizSection,
  bottomSectionTopInner,
  stats,
  mainStats,
  subStats,
  galleries,
} from './../EmsoHolidayPartyThankYou2021/thank-you-2021.module.scss'
import classnames from 'classnames'
import { charities, logoHalo } from '../../components/ThankYou/PartyHeader/PartyHeader.module.scss'
import {
  headingCharity,
  introWrapper,
  charityWrapper,
  backgroundGradient,
  background,
  prizes,
  prizesSection,
} from './thank-you-2022.module.scss'
import halo from '../../data/images/charities/HaloLogoWhite.png'
import { GallerySlides } from '../../components/Gallery/ImageLists/thankyou-2022/GallerySlides'

const ThankYou2022 = () => {
  return (
    <Layout className={pages} blueFooter={false} showNavigation={false} isNonCompliantPage showDisclaimer={false}>
      <Helmet>
        <title>Thank You - Emso</title>
        <meta property="og:title" content="Thank You" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`https://www.emso.com${bgImage}`} />
        <meta name="description" content="Emso Thank You 2022" />
      </Helmet>

      <section className={mainSection}>
        <div className={background} style={{ opacity: 1, backgroundImage: `url(${bgImage})` }} />
        <div className={backgroundGradient} />
        <AnimateEntrance>
          <div className={introWrapper}>
            <div>
              <h2>EMSO HOLIDAY COCKTAIL PARTY 2022</h2>
              <h1>Charity</h1>
              <p>
                The Emso Team was humbled by your generosity at the recent Emso Holiday Cocktail Party! We are particularly grateful to
                those businesses and individuals that donated items to the silent and live auctions.
              </p>
              <br />
              <p>
                In total, over <b>USD 35,000</b> was raised to benefit The HALO Trust, which will cover the cost of 11 metal detectors that
                are urgently needed as HALO continues its crucial work across the globe.
              </p>
              <br />
              <p>
                We know some of you may not have been able to attend on the night or perhaps may have been distracted by the photo booth
                (see photo gallery below!), but if you would like to make a donation directly to HALO, please use the following link:
              </p>
              <a href="https://donorbox.org/halotrust-emso-2022" target="_blank">
                <b>HALO Trust Donations</b>
              </a>
              <br />
              <br />
              <p>
                As the Emso Team wraps up 2022, we wish you and your families a safe and happy holiday season! We look forward to seeing you
                in 2023!
              </p>
            </div>
            <div className={classnames('charityHeader', charityWrapper)}>
              <hr />
              <p className={headingCharity}>In aid of charity</p>
              <div className={charities}>
                <a href="https://www.halotrust.org/" target="_blank">
                  <img src={halo} className={logoHalo} alt="Halo Logo" />
                </a>
              </div>
              <hr />
            </div>
          </div>
        </AnimateEntrance>
      </section>
      <NextSection />
      <div className={quizSection}>
        <div className={bottomSectionTopInner}>
          <div className={galleries}>
            <AnimateEntrance>
              <GallerySlides />
            </AnimateEntrance>
          </div>
          <AnimateEntrance>
            <div className={stats}>
              <div className={mainStats}>
                <h1>With your help and support, the event raised over</h1>
                <h2>$35,000</h2>
              </div>
            </div>
            <div className={subStats}>
              <div>
                <h1>Which will fund the purchase of 11 specialized metal detectors for HALO</h1>
                <h2>11</h2>
              </div>
            </div>
          </AnimateEntrance>
        </div>
        <div className={prizesSection}>
          <AnimateEntrance>
            <div className={prizes}>
              <h1>Prizes included:</h1>
              <div className={mainStats}>
                <h2>6 days / 5 nights at the Viceroy Hotel in St. Lucia</h2>
                <h3>Kindly donated by The Viceroy Sugar Beach Hotel</h3>

                <h2>4 days / 3 night for two people to Colombia to explore Medellin and visit the HALO minefields in the Colombia Andes.</h2>
                <h3>Kindly donated by Emso and HALO </h3>

                <h2>Esin Akan Marylebone Handbag</h2>
                <h3>Kindly donated by Esin Akan</h3>

                <h2>2 Month Membership to WineCult's Wine Club</h2>
                <h3>Kindly donated by WineCult</h3>
              </div>
            </div>
          </AnimateEntrance>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYou2022
