import Gallery, { useGallery } from '../../Gallery'
import { bottomRow, button, buttonRow, gallery, otherPhotos, topRow } from '../thankyou-2021/ThankYouGalleries.module.scss'
import photos from './photos'
import * as React from 'react'
import { Photo } from '../thankyou-2021/ThankYouGalleries'

export const GallerySlides = () => {
  const { imageSelected, isOpen, onImageClicked, setIsOpen } = useGallery()

  return (
    <div className={gallery}>
      <Gallery images={photos} imageSelected={imageSelected} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={topRow}>
        <Photo onClick={onImageClicked} imageUrl={photos[2]} />
        <Photo className={otherPhotos} onClick={onImageClicked} imageUrl={photos[1]} />
        <Photo className={otherPhotos} onClick={onImageClicked} imageUrl={photos[10]} />
      </div>
      <div className={bottomRow}>
        <Photo className={otherPhotos} onClick={onImageClicked} imageUrl={photos[12]} />
        <Photo className={otherPhotos} onClick={onImageClicked} imageUrl={photos[5]} />
        <Photo className={otherPhotos} onClick={onImageClicked} imageUrl={photos[3]} />
      </div>
      <div className={buttonRow}>
        <button className={button} onClick={() => onImageClicked(photos[0])}>
          Holiday Party 2022 Photos
        </button>
      </div>
    </div>
  )
}
